






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class SelectFilesButton extends Vue {
  @Prop({ default: 'Добавить файл из хранилища' })
  private placeholder!: string
}
