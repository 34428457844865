



















import { Component, Prop, Watch } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'

@Component({
  inheritAttrs: false,
})
export default class TextAreaInput extends DetectSafariMixin {
  @Prop({ default: '' })
  private value!: string

  @Prop({ default: '' })
  private label!: string

  @Prop({ default: true })
  private outlined!: boolean

  @Prop({ default: 2 })
  private rows!: number

  @Prop({ default: false })
  private oneLine!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: true })
  private autoGrow!: boolean

  private innerValue = ''

  private mounted () {
    this.innerValue = this.value
  }

  private handleInput (value: string) {
    this.innerValue = value
    this.$emit('input', this.innerValue)
  }

  private handleEnter () {
    this.$emit('enter', this.innerValue)
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
