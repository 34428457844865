import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  ManagerMasterGroupsMasterGroupIdLessonsLessonIdReviewsGetRequest,
  LessonEntityResourceContainer,
  LessonStore,
  LessonReviewResource,
  ManagerLessonLargeResource,
  ManagerMasterGroupsMasterGroupIdLessonsGetParams,
  ManagerMasterGroupsMasterGroupIdLessonsGetRequest,
  ManagerMasterGroupsMasterGroupIdLessonsLessonIdGetRequest,
  ManagerMasterGroupsMasterGroupIdLessonsLessonIdPostRequest,
  ManagerMasterGroupsMasterGroupIdLessonsPostRequest,
  ManagerMasterGroupsMasterGroupIdLessonsLessonIdDeleteRequest,
  LessonCloneData,
  ManagerLessonsLessonIdClonePostRequest,
  LessonLargeResource,
  ManagerLessonsGetRequest,
  IMentorLessonsTable,
  LessonShortResourcePaginator, IMentorLessonsFilter,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'MentorLessons',
  namespaced: true,
  store,
})
class MentorLessons extends VuexModule {
  // ---------------------------- Lessons ---------------------------- >>
  lessonContainers: LessonEntityResourceContainer[] = []
  lessonContainersFilter: IMentorLessonsFilter = GET_DEFAULT_TABLE_FILTER()
  lessonsListFilter: IMentorLessonsTable = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }
  lessonsList: LessonShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setLessonContainers(payload: LessonEntityResourceContainer[]) {
    this.lessonContainers = [...payload]
  }

  @Mutation
  setLessonContainersFilter(payload: IMentorLessonsFilter) {
    this.lessonContainersFilter = Object.assign({}, payload)
  }

  @Mutation
  setLessonsListFilter(payload: IMentorLessonsTable) {
    this.lessonsListFilter = { ...payload }
  }

  @Mutation
  setLessonsList(payload: LessonShortResourcePaginator) {
    this.lessonsList = { ...payload }
  }

  @Action({ rawError: true })
  async fetchLessonContainers(payload: { masterGroupId: number, params: ManagerMasterGroupsMasterGroupIdLessonsGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdLessonsGetRequest(payload.masterGroupId, payload.params)
    this.setLessonContainers(data)
    return data
  }

  @Action({ rawError: true })
  async fetchLessons(payload: { masterGroupId: number, params: ManagerMasterGroupsMasterGroupIdLessonsGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdLessonsGetRequest(payload.masterGroupId, payload.params)
    return data
  }

  @Action({ rawError: true })
  async fetchLessonsList() {
    const { data } = await ManagerLessonsGetRequest(tableOptionsToParams(this.lessonsListFilter))

    this.setLessonsList(data)

    return data
  }

  // ---------------------------- Lesson ---------------------------- >>
  lesson: ManagerLessonLargeResource | null = null

  @Mutation
  setLesson(payload: ManagerLessonLargeResource) {
    this.lesson = payload
  }

  @Action({ rawError: true })
  async fetchLesson(payload: { masterGroupId: number, lessonId: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdLessonsLessonIdGetRequest(payload.masterGroupId, payload.lessonId)
    this.setLesson(data)
    return data
  }

  // Сохранение индивидуального урока в группе MG Plus
  @Action({ rawError: true })
  async saveLesson (payload: { lessonID?: number, masterGroupID: number, params: LessonStore }) {
    if (payload.lessonID) {
      const { data } = await ManagerMasterGroupsMasterGroupIdLessonsLessonIdPostRequest(payload.masterGroupID, payload.lessonID, payload.params)
      return data
    } else {
      const { data } = await ManagerMasterGroupsMasterGroupIdLessonsPostRequest(payload.masterGroupID, payload.params)
      return data
    }
  }

  // Удаление индивидуального урока в группе MG Plus
  @Action({ rawError: true })
  async deleteLesson (payload: { lessonID: number, masterGroupID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdLessonsLessonIdDeleteRequest(payload.masterGroupID, payload.lessonID)
    return data
  }

  // ---------------------------- Lesson Ratings ---------------------------- >>
  @Action({ rawError: true })
  async fetchLessonRating(payload: { masterGroupId: number, lessonId: number }): Promise<LessonReviewResource[]> {
    const { data } = await ManagerMasterGroupsMasterGroupIdLessonsLessonIdReviewsGetRequest(payload.masterGroupId, payload.lessonId)
    return data
  }

  // ---------------------------- Lesson Copy ---------------------------- >>
  @Action({ rawError: true })
  async copyLesson(payload: { lessonId: number, body: LessonCloneData}): Promise<LessonLargeResource> {
    const { data } = await ManagerLessonsLessonIdClonePostRequest(payload.lessonId, payload.body)
    // think about request
    return data
  }

}

const MentorLessonsModule = getModule(MentorLessons)

export default MentorLessonsModule
