
















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LessonForm from '@/components/forms/lesson/LessonForm.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import AuthModule from '@/store/modules/auth'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import { LessonStore } from '@/store/types'
import { convertDateToMSK } from '@/utils/functions'

@Component({
  components: {
    LessonForm,
  },
})
export default class LessonsNew extends Mixins(MasterGroupMixin, NotifyMixin) {
  private get breadcrumbs () {
    return [{ name: 'Уроки', path: '/manager/education/lessons' }]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private created () {
    this.syncMasterGroup()
  }

  private handleNewLesson (form: LessonStore) {
    MentorLessonsModule.saveLesson({
      masterGroupID: this.currentMasterGroupID,
      params: {
        ...form,
        startPublishedAt: form.startPublishedAt ? convertDateToMSK(form.startPublishedAt, this.isLocalTimezone) : form.startPublishedAt,
        spendingAt: convertDateToMSK(form.spendingAt, this.isLocalTimezone),
      },
    })
      .then(() => {
        this.notifySuccess('Урок успешно создан')
        this.$router.push({ name: 'manager.education.lessons' })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Новый урок',
    }
  }
}
