
























import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
// store
import SystemModule from '@/store/modules/system'

interface IAudioData {
  audioID: number,
  playerKey: string,
}

@Component({
  components: {
    ButtonIconAction,
  },
})
export default class Player extends Vue {
  @Prop({ required: true })
  private url!: string

  @Prop({ default: false })
  private border!: boolean

  @Prop({ default: false })
  private deleteBtn!: boolean

  @Prop({ default: false })
  private deleteFileBtn!: boolean

  @Prop({ default: false })
  private download!: boolean

  @Prop({ default: false })
  private playOnly!: boolean

  @Prop({ required: true })
  private audioID!: number

  @Prop({ default: 0 })
  private playerStartTime!: number // Количество секунд от начала

  @Prop({ default: false })
  private isCopyPlayer!: boolean // Плеер, использующийся в спойлерах вопросов

  private get isPlayingGlobal() {
    return SystemModule.isPlaying
  }

  private get isRecordingGlobal() {
    return SystemModule.isRecording
  }

  private get audioList() {
    return SystemModule.audioList
  }

  private isPlay = false
  private isSeeking = false
  private player: HTMLAudioElement | null = null

  private playerKey = `player-${uuid()}`

  private mounted() {
    if (this.$refs.player) {
      this.player = ((this.$refs.player as Vue).$el as HTMLElement).querySelector<HTMLAudioElement>('audio')
      if (this.player) {
        if (this.audioList[this.audioID] !== undefined) {
          this.player.currentTime = this.audioList[this.audioID]
          // Если загруженный плеер - это плеер из спойлера, то отключаем у него звук
          // и если родительский плеер проигрывается, то запускаем и этот
          if (this.isCopyPlayer) {
            this.player.muted = true
            if (this.isPlayingGlobal === this.audioID) {
              this.player.play()
              this.isPlay = true
              ;(this.$refs.player as any).isPlaying = true
            }
          }
        } else {
          this.player.currentTime = this.playerStartTime
          SystemModule.setAudioList({
            currentTime: 0,
            id: this.audioID,
          })
        }
        this.player.addEventListener('play', this.playRecord)
        this.player.addEventListener('pause', this.stopRecord)
        this.player.addEventListener('ended', this.stopRecord)
        this.player.addEventListener('timeupdate', this.updatePlayerTimeline)

        setTimeout(() => {
          this.player?.addEventListener('seeked', this.seeked)
        }, 100)

        this.$bus.$on('playRecord', this.playRecordEqual)
        this.$bus.$on('stopRecord', this.stopRecordEqual)
        this.$bus.$on('seekedPlayer', this.seekedRecordEqual)
      }
    }
  }

  private beforeDestroy() {
    if (this.player) {
      this.player.removeEventListener('play', this.playRecord)
      this.player.removeEventListener('pause', this.stopRecord)
      this.player.removeEventListener('ended', this.stopRecord)
      this.player.removeEventListener('timeupdate', this.updatePlayerTimeline)
      this.player.removeEventListener('seeked', this.seeked)

      this.$bus.$off('playRecord', this.playRecordEqual as any)
      this.$bus.$off('stopRecord', this.stopRecordEqual as any)
      this.$bus.$off('seekedPlayer', this.seekedRecordEqual as any)
    }
  }

  private playRecord() {
    SystemModule.setIsPlaying(this.audioID)
    this.$bus.$emit('playRecord', { audioID: this.audioID, playerKey: this.playerKey })
    this.isPlay = true
  }

  // Воспроизведение остальных плееров, с тем же аудио
  private playRecordEqual(payload: IAudioData) {
    if (payload.audioID === this.audioID && payload.playerKey !== this.playerKey) {
      this.isPlay = true
      if (this.$refs.player) {
        (this.$refs.player as any).isPlaying = true
      }
    }
  }

  private stopRecord() {
    SystemModule.setIsPlaying(null)
    this.$bus.$emit('stopRecord', { audioID: this.audioID, playerKey: this.playerKey })
    this.isPlay = false
  }

  // Остановка остальных плееров, с тем же аудио
  private stopRecordEqual(payload: IAudioData) {
    if (payload.audioID === this.audioID && payload.playerKey !== this.playerKey) {
      this.isPlay = false
      if (this.$refs.player) {
        (this.$refs.player as any).isPlaying = false
      }
    }
  }

  private updatePlayerTimeline(event: Event) {
    SystemModule.setAudioList({
      currentTime: (event.target as any).currentTime,
      id: this.audioID,
    })
  }

  // Перемотка плеера
  // флаг isSeeking указывает на то, что плеер в данный момент начал перемотку и в случае получения события перемотки
  // от другого плеера будет его игнорировать пока не станет false
  private seeked(event: Event) {
    this.isSeeking = true
    this.$bus.$emit('seekedPlayer', { audioID: this.audioID, currentTime: (event.target as any).currentTime, playerKey: this.playerKey })
    setTimeout(() => {
      this.isSeeking = false
    }, 300)
  }

  // Перемотка остальных плееров, с тем же аудио
  private seekedRecordEqual(payload: IAudioData & { currentTime: number }) {
    if (payload.audioID === this.audioID && payload.playerKey !== this.playerKey && !this.isSeeking) {
      if (this.$refs.player) {
        (this.$refs.player as any).player.currentTime = payload.currentTime
      }
    }
  }

  @Watch('isPlayingGlobal')
  private watchIsPlaying(value: number | null) {
    if (value !== null && this.audioID === SystemModule.isPlaying) {
      this.player?.play()
    } else {
      this.player?.pause()
    }
  }
}
