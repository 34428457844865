




































































































































































































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import LessonVideoPlayer from '@/components/forms/lesson/LessonVideoPlayer.vue'
import MastersAccessControl from '@/components/_uikit/controls/MastersAccessControl.vue'
import SelectFilesButton from '@/components/_uikit/buttons/SelectFilesButton.vue'
import SelectFilesModal from '@/components/modals/SelectFilesModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  LessonLargeResource, LessonStore,
  ManagerLessonLargeResource,
  MediaResource, NameValueResource,
  ProgramMonthLargeResource,
} from '@/store/types'
import { formatDate, convertTimeToSeconds, convertSecondsToTime } from '@/utils/functions'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import AuthModule from '@/store/modules/auth'
import { LessonFormType, TimecodeForm } from '@/store/types/forms'

@Component({
  components: {
    AttachmentsWrapper,
    ButtonTextIcon,
    Confirmation,
    DateTimeInput,
    LessonVideoPlayer,
    MastersAccessControl,
    Select,
    SelectFilesButton,
    SelectFilesModal,
    TextAreaInput,
    TextInput,
    TiptapEditor,
    UploadInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class LessonForm extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ default: null })
  private month!: ProgramMonthLargeResource | NameValueResource | null

  @Prop({ default: null })
  private courseID!: number | null

  @Prop({ default: null })
  private currentMasterGroupID!: number

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ default: null })
  private lesson!: LessonLargeResource | ManagerLessonLargeResource | null

  @Prop({ default: false })
  private withTimecodes!: boolean

  @Prop({ default: false })
  private isCurrentMasterGroupPlus!: boolean

  private isCustomTeacherActivate = false

  private innerVideo: MediaResource | null = null
  private showSelectFilesModal = false
  private uploadVideo = false
  private uploadVideoPercent = 0
  private form: LessonFormType = {
    broadcastUrl: '',
    comment: '',
    description: '',
    forAllMasters: false,
    ignorePubDate: false,
    masterIds: [],
    mediaIds: [],
    scriptIds: [],
    spendingAt: '',
    startPublishedAt: '',
    teacher: '',
    teacherId: undefined as unknown as null,
    timecodes: [],
    title: '',
    videoUrl: '',
  }

  private get uploadMaterialParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Уроки/Материалы`,
    }]
  }

  private get uploadScriptParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Уроки/Скрипты`,
    }]
  }

  private get uploadVideoParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Уроки/Видео`,
    }]
  }

  private get monthDate(): string {
    let monthDateString = ''
    if (this.month && Object.hasOwnProperty.call(this.month, 'date')) {
      monthDateString = (this.month as ProgramMonthLargeResource).date
    } else if (this.month && Object.hasOwnProperty.call(this.month, 'name')) {
      monthDateString = (this.month as NameValueResource).name as string
    }

    return monthDateString
  }

  private get teachersList() {
    return ManagerCoursesModule.teachersList
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted () {
    if (!this.teachersList.length)
      ManagerCoursesModule.fetchTeachers()
        .catch(this.notifyError)

    if (this.lesson) {
      this.form = {
        broadcastUrl: this.lesson.broadcastUrl,
        comment: this.lesson.comment,
        description: this.lesson.description,
        forAllMasters: this.lesson.forAllMasters,
        ignorePubDate: this.lesson.ignorePubDate,
        masterIds: (this.lesson as ManagerLessonLargeResource).masters !== undefined ? (this.lesson as ManagerLessonLargeResource).masters.map(master => {
          return master.user.id
        }) : undefined,
        mediaIds: this.lesson.media.map((file: MediaResource) => file.id),
        scriptIds: this.lesson.scripts.map((file: MediaResource) => file.id),
        spendingAt: this.lesson.spendingAt ? formatDate(this.lesson.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone) : this.lesson.spendingAt,
        startPublishedAt: this.lesson.startPublishedAt ? formatDate(this.lesson.startPublishedAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone) : this.lesson.startPublishedAt,
        teacher: this.lesson.teacher,
        teacherId: this.lesson.mainTeacher?.id || undefined as unknown as null,
        timecodes: this.lesson.timecodes.map(timecode => ({
          id: timecode.id,
          localID: timecode.id || Math.random(),
          second: timecode.second,
          time: convertSecondsToTime(timecode.second),
          title: timecode.title,
        })),
        title: this.lesson.title,
        videoId: this.lesson.video?.id,
        videoUrl: this.lesson.videoUrl,
      }

      if (this.form.teacher)
        this.isCustomTeacherActivate = true

      // Если значение для редактора изчанально есть, а потом обновляется с бэка, то в редакторе его не видно
      // Приходится эмитить событие обновления контента в редакторе
      if (this.form.comment)
        this.$bus.$emit('tiptapSetContent', this.form.comment)
      this.innerVideo = this.lesson.video ? Object.assign({}, this.lesson.video) : null

      // Костыль для того, чтобы в случае, когда разные качества видео возвращают 404ю,
      // самое высокое качество заменялось оригиналом
      if (this.innerVideo && this.innerVideo.formats && this.innerVideo.formats.length) {
        this.innerVideo.formats[this.innerVideo.formats.length - 1] = {
          id: this.innerVideo.id,
          label: this.innerVideo.formats[this.innerVideo.formats.length - 1].label,
          quality: this.innerVideo.formats[this.innerVideo.formats.length - 1].quality,
          size: this.innerVideo.size,
          url: this.innerVideo.url,
        }
      }
    }
  }

  private handleUpdateValue (value: number[]) {
    this.form.masterIds = value
  }

  private handleSelectVideoFile (media: MediaResource[]) {

    if (media.length > 1) {
      this.notifyError('Выберите один файл')
    }
    if (media[0].type !== 'video') {
      this.notifyError('Выбранный файл должен быть видео')
    }

    if (media.length === 1 && media[0].type === 'video') {
      this.handleUploadVideo(media[0])
    }
  }

  private triggerUploadVideo () {
    (this.$refs.upload as UploadInput).openPicker()
    this.uploadVideo = true
  }

  private handleUploadVideo (response: MediaResource) {
    this.uploadVideo = false
    this.form.videoId = response.id
    this.innerVideo = response
  }

  private handleUploadProgress (percent: number) {
    this.uploadVideoPercent = +percent
  }

  private confirmDeleteVideo () {
    this.confirm.open(
      'Удаление видео из урока',
      'Вы уверены, что хотите удалить видеозапись из урока?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDeleteVideo)
      .catch(() => {return})
  }

  private handleDeleteVideo () {
    this.form.videoId = undefined
    this.innerVideo = null
  }

  private confirmDeleteLesson () {
    this.confirm.open(
      'Удаление урока',
      'Вы уверены, что хотите удалить урока?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDeleteLesson)
      .catch(() => {return})
  }

  private changeTimecode(value: string, index: number) {
    if (this.form.timecodes) {
      this.form.timecodes = this.form.timecodes.map((timecode: TimecodeForm, idx: number) => {
        if (idx === index) {
          return {
            ...timecode,
            second: convertTimeToSeconds(value),
            time: value,
          }
        }
        return timecode
      })
    }
  }

  private handleDeleteLesson () {
    if (!this.lesson) return

    let monthId!: number
    if (this.month && Object.hasOwnProperty.call(this.month, 'id')) {
      monthId = (this.month as ProgramMonthLargeResource).id
    } else if (this.month && Object.hasOwnProperty.call(this.month, 'value')) {
      monthId = (this.month as NameValueResource).value as number
    }

    // Note: МГ+ - Индивидуальный урок
    if (this.isCurrentMasterGroupPlus && this.currentMasterGroupID) {
      MentorLessonsModule.deleteLesson({
        lessonID: this.lesson.id,
        masterGroupID: this.currentMasterGroupID,
      })
        .then(() => {
          this.notifySuccess('Урок удален')
          this.$router.push({ name: 'manager.education.lessons' })
        })
        .catch(this.notifyError)
      return
    }

    // Note: Обещдостпуный урок в курсе
    if (monthId && this.lesson && this.courseID) {
      ManagerCoursesModule.deleteLesson({
        courseID: this.courseID,
        lessonID: this.lesson.id,
        monthID: monthId,
      })
        .then(() => {
          this.notifySuccess('Урок удален')
          if (Object.hasOwnProperty.call(this.month, 'id')) {
            this.$router.push({
              name: 'manager.control.courses.item.program.month.lessons',
              params: {
                courseID: this.courseID?.toString() as string,
                monthID: monthId.toString() as string,
              },
            })
          } else {
            this.$router.push({ name: 'manager.education.lessons' })
          }
        })
        .catch(this.notifyError)
    }
  }

  private handleAddCustomTeacher() {
    this.isCustomTeacherActivate = true
  }

  private handleDeleteCustomTeacher() {
    this.isCustomTeacherActivate = false
    this.form.teacher = undefined
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    let { broadcastUrl } = this.form

    // Если ссылка на трансляцию это iframe ВК плеера, тогда
    // берем из фрейма ссылку на видео и вычисляем параметры oid, id, hash
    // из которых потом формируем ссылку для сохранения в базу
    // пример ссылки на видео из фрейма: https://vk.com/video_ext.php?oid=-218782988&id=456239021&hash=8eb13eb551ee0965&autoplay=1
    if (broadcastUrl && (broadcastUrl.includes('<iframe src="https://vk.com/video_ext') || broadcastUrl.includes('<iframe src="https://vkvideo.ru/video_ext'))) {
      const params: Record<string, string> = {}
      const link = broadcastUrl.match(/src\s*=\s*"(.+?)"/)
      if (link && link[1]) {
        link[1].split('?')[1].split('&').forEach((item: string) => {
          const s = item.split('=')
          params[s[0]] = s[1]
        })
      }
      broadcastUrl = `https://vk.com/video${params.oid}_${params.id}${params.hash ? `?hash=${params.hash}` : ''}`
    }

    let formSubmit: LessonStore = {
      ...this.form,
      broadcastUrl,
      timecodes: this.form.timecodes ? this.form.timecodes.map((timecode: TimecodeForm) => ({
        id: timecode.id,
        second: timecode.second,
        title: timecode.title,
      })) : [],
    }

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (formSubmit.startPublishedAt && (!formSubmit.videoId && !formSubmit.videoUrl)) {
            this.notifyError('Загрузите видео урока')
            return
          }

          if (this.month && formatDate(formSubmit.spendingAt, 'MM') !== formatDate(this.monthDate, 'MM')) {
            this.confirm.open(
              'Конфликт даты',
              `Дата проведения данного урока <span class="text-body-3 secondary--text">${formatDate(formSubmit.spendingAt, 'dd MMM yyyy, HH:ss', this.isLocalTimezone)}</span>
                       не соответствует заполняемому месяцу <span class="text-body-3 secondary--text">${formatDate(this.monthDate, 'LLLL')}</span> в программе курса.
                       Вы действительно хотите сохранить с текущей датой? После сохранения урок отобразится в месяце, указанном в форме.`,
              {
                buttonConfirmText: 'Сохранить',
                skin: 'secondary',
              },
            )
              .then(() => {
                if (formSubmit.forAllMasters) {
                  formSubmit = {
                    ...formSubmit,
                    masterIds: [],
                  }
                }
                this.$emit('submit', formSubmit)
                requestAnimationFrame(() => (form.reset()))
              })
              .catch(() => {return})
          } else {
            if (formSubmit.forAllMasters) {
              formSubmit = {
                ...formSubmit,
                masterIds: [],
              }
            }
            this.$emit('submit', formSubmit)
            requestAnimationFrame(() => (form.reset()))
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
