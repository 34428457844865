
















import { Component, Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import MasterMultipleSearch from '@/components/_uikit/search/MasterMultipleSearch.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'

// Управление доступом к индивидуальным урокам или домашним заданиям
@Component({
  components: {
    MasterMultipleSearch,
    SwitchInput,
  },
})
export default class MastersAccessControl extends NotifyMixin {
  @Prop({ required: true })
  private value!: number[]

  @Prop({ default: false })
  private forAllMasters!: boolean

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  private innerMasterIds: number[] = []
  private innerForAllMasters = false

  @Watch('forAllMasters')
  watchForMastersAll(value: boolean) {
    this.innerForAllMasters = value
  }

  @Watch('innerForAllMasters')
  watchForAllMasters(value: boolean) {
    this.$emit('update:forAllMasters', value)
  }

  @Watch('innerMasterIds')
  watchMasterIds(value: number[]) {
    this.$emit('update:value', value)
  }

  @Watch('value', { deep: true })
  watchValue(value: number[]) {
    this.innerMasterIds = value
  }
}
