


































































import { ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MediaResource } from '@/store/types'
import { LessonFormType, TimecodeForm } from '@/store/types/forms'
import { convertSecondsToTime } from '@/utils/functions'

@Component({
  components: {
    TextInput,
    ValidationProvider,
  },
})
export default class LessonVideoPlayer extends Mixins(NotifyMixin) {
  @Ref() plyr?: any

  @Prop({ required: true })
  private value!: MediaResource | null

  @Prop({ required: true })
  private form!: LessonFormType

  @Ref() video!: HTMLVideoElement

  private timeMask = [/\d/, ':', /[0-5]/, /\d/, ':', /[0-5]/, /\d/]
  private timePattern = new RegExp(/^\d:[0-5]\d:[0-5]\d$/m)

  private handleAddTimecode () {
    if (this.form.timecodes) {
      this.form.timecodes.push({
        localID: Math.random(),
        second: this.video ? Math.floor(this.video.currentTime) || 0 : 0,
        time: this.video ? convertSecondsToTime(Math.floor(this.video.currentTime)) : '',
        title: '',
      })
    }
  }

  private handleDeleteTimecode (index: number) {
    if (this.form.timecodes) {
      this.form.timecodes.splice(index, 1)
    }
  }

  private copyTimecodes() {
    if (this.form.timecodes && this.form.timecodes.length) {
      navigator.clipboard.writeText(JSON.stringify(this.form.timecodes.map((timecode: TimecodeForm) => ({ second: timecode.second, time: timecode.time, title: timecode.title }))))
      this.notifyInfo('Таймкоды скопированы в буфер обмена')
    }
  }

  private pasteTimecodes() {
    navigator.clipboard.readText()
      .then(timecodesBuffer => {
        const timecodes = JSON.parse(timecodesBuffer)
        // Проверяем, что вставлять мы будем именно таймкоды, а не рандомный текст
        if (Array.isArray(timecodes) && timecodes.length && timecodes.every((timecode: any) => Object.prototype.hasOwnProperty.call(timecode, 'second') && Object.prototype.hasOwnProperty.call(timecode, 'title'))) {
          if (this.form.timecodes) {
            const tc = timecodes.map(timecode => ({ ...timecode, localID: Math.random() }))
            this.form.timecodes.push(...tc)
          }
          return
        }
        this.notifyError('Буфер обмена не содержит таймкодов')
      })
      .catch(() => this.notifyError('Буфер обмена не содержит таймкодов'))
  }

  private beforeDestroy() {
    if (this.plyr)
      this.plyr.player.destroy()
  }
}
