











































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// mixins
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import ManagerGroupsModule from '@/store/modules/manager/groups'
// types
import { UserShortResource } from '@/store/types'

@Component
export default class MasterMultipleSearch extends Mixins(DetectSafariMixin, MasterGroupMixin) {
  @Prop({ default: 'Добавить' })
  private selectText!: string

  @Prop({ default: () => ([]) })
  private value!: number[]

  @Prop({ default: true })
  private multiple!: boolean

  @Prop({ default: 'Выберите пользователей' })
  private label!: string

  @Prop({ default: 'Начните вводить для поиска' })
  private placeholder!: string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: () => ([]) })
  private masterIds!: number[]

  private isLoading = false
  private mastersFound: UserShortResource[] = []
  private mastersSelected: UserShortResource[] = []
  private search = ''

  private mounted () {
    if (this.value.length > 0) {
      this.mastersSelected = this.mastersFound.filter(master => {
        return this.value.some(masterId => master.id === masterId)
      })
    }
  }

  private get mastersSelectedIds (): number[] {
    return this.mastersSelected.map(item => item.id)
  }

  private handleInput () {
    this.$emit('update:value', this.mastersSelectedIds)
  }

  private handleBlur() {
    this.search = ''
  }

  private handleDelete (item: UserShortResource) {
    const index = this.mastersSelected.indexOf(item)
    if (index >= 0) {
      this.mastersSelected.splice(index, 1)
      this.$emit('update:value', this.mastersSelectedIds)
    }
  }

  @Bind
  @Debounce(500)
  private handleFetchMasters () {
    if (this.isLoading) return

    this.isLoading = true

    ManagerGroupsModule.fetchGroupMastersSelect({ filter: { query: this.search }, masterGroupID: this.currentMasterGroupID })
      .then((response: UserShortResource[]) => {
        this.mastersFound = [...response]
      })
      .catch(this.notifyError)
      .finally(() => (this.isLoading = false))
  }

  @Watch('search')
  private watchSearch() {
    this.handleFetchMasters()
  }

  @Watch('mastersFound')
  private watchMasterIds(value: UserShortResource[], prevValue: UserShortResource[]) {
    if (!prevValue.length) {
      if (this.value.length > 0) {
        this.mastersSelected = this.mastersFound.filter(master => {
          return this.value.some(masterId => master.id === masterId)
        })
      }
    }
  }
}
