
























































import { DateTime } from 'luxon'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import TextInput from '@/components/_uikit/controls/TextInput.vue'

@Component({
  components: {
    TextInput,
  },
  inheritAttrs: false,
})
export default class DateTimeInput extends Vue {
  @Ref() menu!: any

  @Prop({ default: '' })
  private value!: string

  @Prop({ default: null })
  private label!: string

  @Prop({ default: null })
  private max!: string

  @Prop({ default: '1950-01-01' })
  private min!: string

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private onlyHours!: boolean

  private tab = 0
  private innerDateValue = ''
  private innerTimeValue = ''
  private showPicker = false

  private get formattedDateTime () {
    return this.selectedDateTime || ''
  }

  private get selectedDateTime () {
    if (this.innerDateValue && this.innerTimeValue) {
      return DateTime.fromSQL(`${this.innerDateValue} ${this.innerTimeValue}`).toFormat('dd MMM yyyy, HH:mm')
    } else {
      return ''
    }
  }

  private mounted () {
    if (this.value) {
      this.sync(this.value)
    }
  }

  private sync (value: string) {
    let initDateTime = DateTime.fromSQL(value)
    this.innerDateValue = initDateTime.toFormat('yyyy-MM-dd')
    this.innerTimeValue = initDateTime.toFormat('HH:mm')
  }

  private handleHourClick(hours: number) {
    if (this.onlyHours) {
      this.innerTimeValue = hours.toString().length > 1 ? `${hours}:00` : `0${hours}:00`
      this.handleInput()
    }
  }

  private handleInput () {
    if (this.innerDateValue && this.innerTimeValue) {
      const dateTime = `${this.innerDateValue} ${this.innerTimeValue}`
      this.$emit('update:value', dateTime)
      this.$emit('input', dateTime)
    }
    this.handleReset()
  }

  private handleClear() {
    this.innerDateValue = ''
    this.innerTimeValue = ''
    this.$emit('update:value', null)
    this.$emit('input', null)
    this.handleReset()
  }

  private handleReset() {
    this.menu.save()
    this.tab = 0
    if (this.$refs.timer) {
      (this.$refs.timer as any).selectingHour = true
    }
  }

  @Watch('value')
  private watchValue (value: string) {
    if (value) {
      this.sync(value)
    } else {
      this.handleClear()
    }
  }
}
